.clickable-address {
  padding: 5px 10px;
}

.clickable-address:hover {
  background: #2d3596;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  animation: fadeIn .3s;
}

.parent-brand  .tooltiptext{
  visibility: hidden;
  width: 120px;
  background-color: #3b3b3e;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
  left: 70px;
}
@keyframes transition{
  from{opacity: 0;}
  to{opacity: 1;}
}
.parent-brand:hover .tooltiptext {
  visibility: visible;
  animation: transition .4s;
}

.parent-brand {
  position: relative;
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
  &:hover {
    background-size: 100% 88%;
  }
}
